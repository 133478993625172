import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import {
    Link,
    useLocation,
} from 'react-router-dom';

import { Routes } from '../../../constants';

import { adaptPage } from '../../helpers/adapters';

import Styles from './Breadcrumbs.module.css';
import AppStyles from '../../../App.module.css';

const Breadcrumbs = () => {
    const { pathname } = useLocation();

    const pages = useSelector(state => state.pages.data);
    const posts = useSelector(state => state.posts.data);
    
    let page = pages.find(item => item.acf.slug === pathname);
    let pageItem = {};

    if (page) {
        pageItem = adaptPage(page);
    }

    let title = pageItem.title;

    if (!page && pathname.includes(Routes.JOBS)) {
        const post = posts.find((item)=> Routes.JOBS_SINGLE.replace(':slug',item.slug) === pathname);
        if (post) {
            title = `CANDIDATURAS > ${post.title.rendered}`;
        }
    }

    if (pageItem.slug === Routes.SERVICES_COMPANIES ||
        pageItem.slug === Routes.SERVICES_PEOPLE) {
        title = `SERVIÇOS > ${title}`;
    }

    const bread = `INÍCIO > ${title}`;

    return (
        <div className={ classNames(Styles.breadcrumbs, AppStyles.addMobilePadding) }>
            <div className={ classNames(Styles.box, AppStyles.container) }>
                <Link
                    className={ Styles.back }
                    to={Routes.HOME}>
                    <div className={ Styles.goBack } />
                    <span>
                        voltar
                    </span>
                </Link>
                <span className={ Styles.title }>
                    { bread }
                </span>
            </div>
        </div>
    );
};

export default Breadcrumbs;