import React from 'react';
import classNames from 'classnames';

import ServicesCard from '../../shared/components/services-card/ServicesCard';
import ServicesInformation from '../../shared/components/services-information/ServicesInformation';

import { Routes } from '../../constants';

import Styles from './ServicesPage.module.css';
import AppStyles from '../../App.module.css';

const ServicesPage = () => {
    return (
        <div className={ classNames(Styles.services, AppStyles.addMobilePadding) }>
            <ServicesCard />

            <ServicesInformation
                title="Empresas"
                knowMoreUrl={Routes.SERVICES_COMPANIES}
                blocks={[
                    {
                        title: 'Executive Search',
                        target: 'executive-search'
                    },
                    {
                        title: 'Recrutamento & Seleção',
                        target: 'recruitment-selection'
                    },
                    {
                        title: 'Assessment - Avaliação Psicológica',
                        target: 'assessment'
                    },
                    {
                        title: 'Planos de sucessão',
                        target: 'succession-plans'
                    }
                ]}>
                <p>
                    Os nossos serviços representam soluções flexíveis de resposta para os problemas de Talento do seu negócio. Flexíveis porque mais do que divisões artificiais elas são co-construídas em parceria com o Cliente promovendo-se os vectores da Cultura Organizacional, Transformação e Conhecimento. 
                </p>
            </ServicesInformation>

            <ServicesInformation
                title="Pessoas"
                knowMoreUrl={Routes.SERVICES_PEOPLE}
                blocks={[
                    {
                        title: 'Oportunidades de Emprego',
                        target: 'jobs'
                    },
                    {
                        title: 'Candidatura Espontânea',
                        target: 'spontaneous-application'
                    },
                    {
                        title: 'Aconselhamento',
                        target: 'help'
                    }
                ]}>
                <p>
                    O nosso compromisso para com as pessoas é de ética e respeito pela privacidade e dignidade de quem deposita em nós o seu tempo e expectativas em momentos cruciais para o seu percurso de vida.
                </p>
            </ServicesInformation>
        </div>
    );
};

export default ServicesPage;