import React from 'react';
import {
    NavLink,
    useHistory
} from 'react-router-dom';
import classNames from 'classnames';
import { scroller } from 'react-scroll';

import { Routes } from '../../../../constants';

import Styles from './TopNav.module.css';

const TopNav = () => {
    let history = useHistory();

    function goToHomeContacts() {
        history.push(Routes.HOME);
        setTimeout(() => {
            scroller.scrollTo('contacts', {
                // duration: 1500,
                // delay: 100,
                smooth: true,
                offset: -60, // Scrolls to element + 50 pixels down the page
            }); 
        }, 100);
    }

    return (
        <>
            <div className={ Styles.navigation }>
                <ul className={ Styles.list }>
                    <li className={ Styles.listItem }>
                        <NavLink
                            exact
                            activeClassName={ Styles.active }
                            to={Routes.HOME}>
                            Início
                        </NavLink>
                    </li>
                    <li className={ Styles.listItem }>
                        <NavLink
                            activeClassName={ Styles.active }
                            to={Routes.SERVICES}>
                            Serviços
                        </NavLink>
                    </li>
                    <li className={ Styles.listItem }>
                        <NavLink
                            activeClassName={ Styles.active }
                            to={Routes.JOBS}>
                            Candidaturas
                        </NavLink>
                    </li>
                    <li className={ classNames(Styles.listItem, Styles.highlight) }>
                        <a
                            role="button"
                            tabIndex="0"
                            onClick={ goToHomeContacts }>
                            Contacto
                        </a>
                    </li>
                </ul>
            </div>
        </>
    );
};

export default TopNav;
