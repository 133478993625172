import React from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { adaptPage } from '../../helpers/adapters';

import { Routes } from '../../../constants';

const Seo = () => {
    let { pathname } = useLocation();
    
    if (pathname.includes(Routes.JOBS)) {
        pathname = Routes.JOBS;
    }

    const pages = useSelector(state => state.pages.data);

    let page = pages.find(item => item.acf.slug === pathname);
    let pageItem = {};

    if (page) {
        pageItem = adaptPage(page);
    }

    const title = pageItem.seoTitle || 'Recursos humanos';
    const description = pageItem.seoDescription || 'Recursos humanos';

    return (
        <Helmet>
            <title>Sinapse - {title}</title>
            <meta name="description" content={ description } />
        </Helmet>
    );
};

Seo.propTypes = {
    pathname: PropTypes.string.isRequired,
};

export default Seo;