import React, { useEffect } from 'react';
// import {
//     Link,
// } from 'react-router-dom';
// import classNames from 'classnames';
// import { useSelector } from 'react-redux';
// import { take } from 'lodash';

// import { Routes } from '../../constants';
// import { adaptNews } from '../../shared/helpers/adapters';

import Services from './sections/Services';
import WhySinapse from './sections/WhySinapse';
import TheBrand from './sections/TheBrand';
import ContactUs from './sections/ContactUs';
import ContactForm from '../../shared/components/forms/ContactForm';

// import Styles from './HomePage.module.css';
import AppStyles from '../../App.module.css';

const HomePage = () => {
    // const posts = take(useSelector(state => state.posts.data), 3);
    // const news = adaptNews(posts);
    // const pages = useSelector(state => state.pages.data);
    // const homepage = pages.find(element => element.acf.slug === Routes.HOME);

    useEffect(() => {
        // console.log('render');
        // const hash = window.location.hash;
        // console.log('homepage', homepage);
    });

    return (
        <div>
            <div className={AppStyles.addMobilePadding}>
                <Services />
            </div>
            
            <div className={AppStyles.addMobilePadding}>
                <WhySinapse />
            </div>

            <TheBrand />

            <div className={AppStyles.addMobilePadding}>
                <ContactUs />
            </div>

            <ContactForm />
        </div>
    );
};

export default HomePage;