import React, { useEffect } from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import Cookies from 'js-cookie';

import * as pageActions from '../../state/page/actions';

import Styles from './CookiesConsent.module.css';
import AppStyles from '../../../App.module.css';

const CookiesConsent = () => {
    const dispatch = useDispatch();
    const cookiesConsent = useSelector(state => state.page.cookies);

    const handleClick = () => {
        Cookies.set('cookies_consented', 'true');
        dispatch(pageActions.setCookies(true));
    };

    if (cookiesConsent) {
        return null;
    }

    return (
        <div className={ Styles.cookies }>
            <div className={ classNames(Styles.box, AppStyles.container) }>
                <span>
                Este website usa cookies para melhorar a sua experiência como utilizador.  Ao continuar a navegar neste site, concorda com a sua utilização.
                </span>
                <a
                    onClick={() => handleClick()}
                    className={ Styles.action }
                    href="#accept">
                    OK!
                </a>
            </div>
        </div>
    );
};

export default CookiesConsent;