import React from 'react';
import classNames from 'classnames';
import { Element } from 'react-scroll';

import MapImage from '../../../images/mapa_3.jpg';
import IconPhone from '../../../images/icon_phone.svg';
import IconMail from '../../../images/icon_mail.svg';

import Styles from './ContactUs.module.css';
import AppStyles from '../../../App.module.css';

const ContactUs = () => {
    return (
        <>
            <div
                id="contacts"
                className={ classNames(Styles.contacts, AppStyles.container) }>
                <Element name="contacts" />
                <h1>
                    Fale Connosco                
                </h1>
                <div className={ Styles.box }>
                    <div className={ Styles.text }>
                        <div className={ Styles.title }>
                            Sinapse RH
                        </div>
                        <div className={ Styles.subTitle }>
                            Consultores em Recursos Humanos
                        </div>
                        <div className={ Styles.address }>
                            Av. Calouste Gulbenkian 9, Piso -2 LJ47 <br/>
                            3000-092 <br/>
                            Coimbra
                        </div>
                        <div className={ Styles.goToMap }>
                            <a href="#mapa">
                                Ver no mapa
                            </a>
                        </div>
                        <div className={ Styles.links }>
                            <div className={ Styles.link }>
                                <span>
                                    <img src={ IconPhone } alt=""/>
                                </span>
                                <a href="tel:919900488">
                                    919 900 488
                                </a>
                            </div>
                            <div className={ Styles.link }>
                                <span>
                                    <img src={ IconMail } alt=""/>
                                </span>
                                <a href="mailto:geral@sinapserh.pt">
                                    geral@sinapserh.pt
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className={ Styles.map }>
                        <a href="#mapa">
                            <img src={ MapImage } alt="Localização"/>
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContactUs;