import React from 'react';
import classNames from 'classnames';
import { scroller, Element } from 'react-scroll';
import { useSelector } from 'react-redux';

import JobsTable from '../../shared/components/jobs-table/JobsTable';
import JobForm from '../../shared/components/forms/SpontaneousJobForm';

import { adaptJob } from '../../shared/helpers/adapters';

import IconJobsSearch from '../../images/icon_jobs_search.svg';

import Styles from './ListPage.module.css';
import AppStyles from '../../App.module.css';

const ListPage = () => {
    const posts = useSelector(state => state.posts.data);

    let jobs = posts.map((post)=> adaptJob(post));

    // if (!jobs.length) { //TODO remove before deploy live
    //     jobs = [
    //         {
    //             id: 1,
    //             url: 'candidaturas/ccc',
    //             position: 'Técnico Superior dos Serviços de Manutenção/Energia (m/f)',
    //             company: 'SinapseRH',
    //             location: 'Angra do Heroísmo',
    //             date: '22 Jan',
    //             status: 'Active'
    //         },
    //         {
    //             id: 2,
    //             url: 'ccc',
    //             position: 'Técnico Superior dos Serviços de Manutenção/Energia (m/f)',
    //             company: 'SinapseRH',
    //             location: 'Angra do Heroísmo',
    //             date: '22 Jan',
    //             status: 'Archived'
    //         },
    //     ];
    // }

    const goToForm = () => {
        scroller.scrollTo('jobs-spontaneous', {
            // duration: 1500,
            // delay: 100,
            smooth: true,
            offset: -80, // Scrolls to element + 50 pixels down the page
        }); 
    };

    return (
        <>
            <div className={ classNames(Styles.header, AppStyles.container, AppStyles.addMobilePadding) }>
                <div className={ Styles.image }>
                    <img src={ IconJobsSearch } alt=""/>
                </div>
                <div className={ Styles.title }>
                    <h1>
                            Candidaturas
                    </h1>
                    <h2>
                        & <span>Oportunidades</span>
                    </h2>
                </div>
                <div className={ Styles.spontaneous }>
                    <a
                        role="button"
                        tabIndex="0"
                        onClick={ goToForm }>
                            Candidatura Espontânea
                        <div className={ Styles.iconGo } />
                    </a>
                </div>
            </div>

            <Element name="jobs-table" />

            <div className={ Styles.jobsWrapper }>
                <div className={ classNames(Styles.jobs, AppStyles.container) }>
                    <h4>
                        Oportunidades <br />
                        de emprego
                    </h4>

                    <JobsTable jobs={jobs} />
                </div>
            </div>

            <Element name="jobs-spontaneous" />

            <JobForm />
        </>
    );
};

export default ListPage;