/*global process*/
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import moment from 'moment';
import Cookies from 'js-cookie';

import configureStore from './shared/store/configureStore';
import defaultConfig from './config/default';
import devConfig from './config/default.dev';

import Routing from './Routing';
import ScrollToTop from './shared/components/layout/ScrollToTop';
import Layout from './shared/components/layout/Layout';
import CookiesConsent from './shared/components/cookies-consent/CookiesConsent';
import * as pageActions from './shared/state/page/actions';

import './fonts/filson-pro/FilsonProRegular-Regular.otf';

const store = configureStore();

// import logo from './logo.svg';
// import './App.css';

// const baseUrl = window.location.origin;
// console.log('baseUrl', baseUrl);

// console.log('defaultconfig: ', defaultConfig);
// console.log('devconfig: ', devConfig);

let appConfig = { ...defaultConfig };
if (process.env.NODE_ENV === 'development') {
    // console.log('dev mode');
    appConfig = { ...devConfig };
}

store.dispatch(pageActions.setConfig(appConfig));

store.dispatch(pageActions.init());

moment.locale('pt'); // 'en'

const cookiesConsented = Cookies.get('cookies_consented');
if (cookiesConsented) {
    const cookiesConsentedValue = JSON.parse(cookiesConsented);
    store.dispatch(pageActions.setCookies(cookiesConsentedValue));
}

function App() {
    return (
        <Provider store={store}>
            <Router>
                <ScrollToTop />
                <Layout>
                    <CookiesConsent />
                    <Routing />
                </Layout>
            </Router>
        </Provider>
    );
}

export default App;
