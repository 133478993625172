import React from 'react';
import {
    useHistory
} from 'react-router-dom';
import classNames from 'classnames';

import Styles from './JobsTable.module.css';
import AppStyles from '../../../App.module.css';

const JobsTable = ({ jobs }) => {
    let history = useHistory();

    function handleJobClick(pageUrl) {
        console.log('handleJobClick', pageUrl);
        history.push(pageUrl);
    }

    return (
        <div className={ classNames(Styles.jobs, AppStyles.addMobilePadding) }>
            <table cellSpacing="10">
                <thead>
                    <tr>
                        <th>
                            Função
                        </th>
                        <th>
                            Localização
                        </th>
                        <th>
                            Empresa
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        jobs.map((job) => (
                            <tr key={job.id} onClick={ () => handleJobClick(job.url) }>
                                <td className={ Styles.position }>{ job.role }</td>
                                <td className={ Styles.location }>{ job.location }</td>
                                <td className={ Styles.company }>{ job.company }</td>
                                <td className={ Styles.icon }>
                                    <div className={ Styles.iconGo } />
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    );
};

export default JobsTable;
