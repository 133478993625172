import React from 'react';
import classNames from 'classnames';

import Styles from './Terms.module.css';
import AppStyles from '../../App.module.css';

const TermsPage = () => {
    return (
        <div className={ classNames(Styles.terms, AppStyles.container, AppStyles.addMobilePadding) }>
            <h3>
                Política de Cookies
            </h3>
            
            <p>
                Utilizamos cookies no nosso website para melhorar o desempenho e a sua experiência como utilizador. Este texto explica como o fazemos. 
            </p>
            <p>
                <strong>O que são cookies? </strong> <br />
                "Cookies" são pequenos ficheiros de texto que são armazenados no seu computador ou no seu dispositivo móvel através do navegador de internet (browser), retendo apenas informação relacionada com as suas preferências, não incluindo, como tal, os seus dados pessoais. A colocação de cookies ajudará o website a reconhecer o seu dispositivo na próxima vez que o utilizador o visita. 
            </p>
            <p>
                Usamos o termo cookies nesta política para referir todos os ficheiros que recolhem informações desta forma. 
            </p>
            <p>
                Os cookies utilizados não recolhem informação que identifica o utilizador. Os cookies recolhem informações genéricas, designadamente a forma como os utilizadores chegam e utilizam os websites ou a zona do país/países através do qual acedem ao website, etc. 
            </p>
            <p>
                Os cookies retêm apenas informação relacionada com as suas preferências. A qualquer momento o utilizador pode, através do seu navegador de internet (browser) decidir ser notificado sobre a recepção de cookies, bem como bloquear a respectiva entrada no seu sistema. 
            </p>
            <p>
                A recusa de uso de cookies no website, pode resultar na impossibilidade de ter acesso a algumas das suas áreas ou de receber informação personalizada. 
            </p>
            <p>
                <strong>Para que servem os Cookies?</strong> <br />
                Os cookies servem para ajudar a determinar a utilidade, interesse e o número de utilizações dos seus websites, permitindo uma navegação mais rápida e eficiente, eliminando a necessidade de introduzir repetidamente as mesmas informações. 
            </p>
            <p>
                <strong>Como pode gerir os cookies?</strong> <br />
                Os cookies ajudam a tirar o máximo proveito dos websites no entanto, todos os navegadores de internet (browsers) permitem ao utilizador aceitar, recusar ou apagar cookies, nomeadamente através da selecção das definições apropriadas no respectivo navegador de internet (browser). Depois de autorizar o uso de cookies, o utilizador pode sempre desactivar parte ou a totalidade dos nossos cookies. Para o efeito, o utilizador deve seguir as seguintes instruções:  <br />
                Configurações de Cookies no Internet Explorer - Desactivar cookies 
            </p>
            <p>
                Clique no menu "Ferramentas" e escolha a opção "Opções de Internet";  <br />
                Seleccione o separador "Privacidade";  <br />
                Mova a barra para o topo, onde surgirá "Bloquear todos os cookies". 
            </p>
            <p>
                Nota: Existem vários níveis de activação e desactivação de cookies no Internet Explorer. Para mais informações sobre definições de cookies Internet Explorer, consulte a seguinte página da Microsoft: 
            </p>
            <p>
                http://windows.microsoft.com/pt-PT/windows-vista/Block-or-allow-cookies  <br />
                Configurações de Cookies no Mozilla Firefox - Desactivar cookies 
            </p>
            <p>
                Clique no menu "Ferramentas";  <br />
                Seleccione "Opções";  <br />
                Clique no ícone "Privacidade", que encontra no painel superior;  <br />
                Na secção "Cookies" desactive a opção "Aceitar cookies dos websites";  <br />
                Clique em "OK" para guardar as alterações e fechar. 
            </p>
            <p>
                Nota: Existem vários níveis de activação e desactivação de cookies no Mozilla Firefox. Para mais informações sobre definições de cookies no Mozilla Firefox, consulte a seguinte página : 
            </p>
            <p>
                http://support.mozilla.org/pt-PT/kb/Activar%20e%20Desactivar%20cookies?redirectlocale=en- US&redirectslug=Enabling+and+disabling+cookiesMozilla Firefox  <br />
                Configurações de Cookies no Chrome - Desactivar cookies 
            </p>
            <p>
                Clique no menu do Chrome na barra de ferramentas do navegador.  <br />
                Seleccione Definições.  <br />
                Clique em Mostrar definições avançadas.  <br />
                Na secção "Privacidade", clique no botão Definições de conteúdo.  <br />
                Seleccione a opção "Bloquear definição dos dados por parte dos websites". 
            </p>
            <p>
                Pode alterar as seguintes definições de cookies na secção "Cookies": 
            </p>
            <p>
                Eliminar cookies  <br />
                Bloquear cookies por predefinição  <br />
                Permitir cookies por predefinição  <br />
                Manter cookies e dados do Website por predefinição até sair do navegador de internet (browser)  <br />
                Criar excepções para cookies de Websites ou domínios específicos. 
            </p>
            <p>
                Nota: Existem vários níveis de activação e desactivação de cookies no Google Chrome. Para mais informações sobre definições de cookies no Google Chrome, consulte a seguinte página : 
            </p>
            <p>
                http://support.google.com/chrome/bin/answer.py?hl=pt&answer=95647&topic=14666&ctx=topic  <br />
                Configurações de Cookies em Safari Web e IOS - Desactivar cookies 
            </p>
            <p>
                Clique em "Editar";  <br />
                Seleccione "Preferências";  <br />
                No painel superior, seleccione o ícone "Privacidade";  <br />
                Na secção "Aceitar cookies", seleccione "Nunca". 
            </p>
            <p>
                Nota: Existem vários níveis de activação e desactivação de cookies no Safari. Para mais informações sobre definições de cookies no Safari, consulte a seguinte página : 
            </p>
            <p>
                http://support.apple.com/kb/HT1677?viewlocale=pt_PT  <br />
                Outros navegadores de internet (browser) 
            </p>
            <p>
                Por favor, procure no menu "ajuda" do navegador de internet (browser) ou contacte o fornecedor do navegador de internet (browser). 
            </p>
            <p>
                NOTA: Recordamos que ao desactivar os cookies, partes do nosso website podem não funcionar correctamente. 
            </p>
            <p>
                <strong>Links</strong> <br />
                Para obter mais informações sobre cookies e a respectiva utilização, sugerimos que consulte os seguintes links, que apenas se encontram em versão inglesa: 
            </p>
            <p>
                www.allaboutcookies.org
            </p>
        </div>
    );
};

export default TermsPage;