import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
// import {
//     Link,
// } from 'react-router-dom';

// import { Routes } from '../../../constants';

import { submit, reset } from '../../state/form/actions';

import IconUpload from '../../../images/icon_upload.svg';
import IconClose from '../../../images/icon_close.svg';

import Styles from './Form.module.css';
import AppStyles from '../../../App.module.css';

const regexOnlyNumbers = /^(\d+|^$)$/;
// const regexEmail = '';

const CheckboxField = ({ id, required = true, label, value, onChange }) => {
    return (
        <div className={ Styles.checkboxField }>
            <label className={ Styles.checkbox }>
                {/* Aceito os <Link to={ Routes.TERMS }>Termos e Política de Privacidade</Link> */}
                { label }
                <input
                    id={ id }
                    type="checkbox"
                    checked={ value }
                    onChange={ (e) => onChange(id, e.target.value, e.target.checked) }/>
                <span className={ Styles.checkmark }></span>
            </label>
        </div>
    );
};

const InputField = ({ id, required = true, label, placeholder, value, type, error, size, onChange }) => {
    const hasError = error && error.touched && error.error;
    return (
        <div className={ classNames(Styles.inputField, { [Styles.full]: size === 'full' }) }>
            <label
                className={ classNames({ [Styles.error]: hasError }) }
                htmlFor={ id }>
                { label } { required ? ' *' : '' }
            </label>
            <input
                id={ id }
                className={ classNames({ [Styles.error]: hasError }) }
                value={ value }
                type={ type }
                placeholder={ placeholder }
                onChange={ (e) => onChange(id, e.target.value) }/>
        </div>
    );
};

const TextareaField = ({ id, required = true, label, placeholder, value, error, onChange }) => {
    const hasError = error && error.touched && error.error;
    return (
        <div className={ Styles.textareaField }>
            <label
                className={ classNames({ [Styles.error]: hasError }) }
                htmlFor={ id }>
                { label } { required ? ' *' : '' }
            </label>
            <textarea
                id={ id }
                className={ classNames({ [Styles.error]: hasError }) }
                value={ value }
                placeholder={ placeholder }
                onChange={ (e) => onChange(id, e.target.value) }/>
        </div>
    );
};

const FileField = ({ id, placeholder, value, error, onChange }) => {
    const hasError = error && error.touched && error.error;
    return (
        <div className={ Styles.fileField }>
            {
                !value &&
                <>
                    <input
                        id={ id }
                        type="file"
                        onChange={ (e) => onChange(id, e.target.files[0]) }/>
                    <label
                        htmlFor={ id }
                        className={ classNames({ [Styles.error]: hasError }) }>
                        <span>
                            { placeholder }
                        </span>
                        <img src={ IconUpload } alt={ placeholder }/>
                    </label>
                </>
            }
            {
                value &&
                <label
                    className={ Styles.hasImage }
                    onClick={ () => onChange(id, null) }>
                    {
                        value &&
                        <>
                            <span>
                                { value.name.substring(0, 20) }
                            </span>
                            <img src={ IconClose } alt="Remover anexo"/>
                        </>
                    }
                </label>
            }
        </div>
    );
};

const Form = ({ title, formik, job }) => {
    const dispatch = useDispatch();
    const saving = useSelector(state => state.form.saving);
    const success = useSelector(state => state.form.success);
    
    const [formState, setFormState] = useState({});
    const [formErrorsState, setFormErrorsState] = useState({});
    const [formIsValid, setFormIsValid] = useState(false);
    const [shouldResetForm, setShouldResetForm] = useState(true);
    
    const onChange = (property, value, checked) => {
        // console.log('onchange:', property, value, checked);

        const formItem = formik.find((item)=> item.id === property);

        if (formItem.type === 'tel') {
            if (!regexOnlyNumbers.test(value)) {
                return;
            }
        }
        else if (formItem.type === 'checkbox') {
            value = checked;
        }

        setFormState({
            ...formState,
            [property]: value
        });
    };

    const onClick = () => {
        // e.preventDefault();

        // console.log('button click');

        // Validate form
        // Again??
        const formData = formik.reduce((acc, item)=>[
            ...acc,
            {
                key: item.id,
                value: formState[item.id],
            }
        ], []);

        const data = new FormData();
        data.append('s-formtype', title);
        
        if (job) {
            data.append('s-job', job);
            data.append('s-to-email', 'candidatos@sinapserh.pt');
        } else {
            data.append('s-to-email', 'geral@sinapserh.pt');
        }

        for (const [key, item] of Object.entries(formData)) {
            if (item.key === 'file') {
                console.log(`s-${item.key}`, item.value, item.value.name);
                data.append(`s-${item.key}`, item.value, item.value.name);
            }
            else {
                console.log(`s-${item.key}`, item.value);
                data.append(`s-${item.key}`, item.value);
            }
        }

        // console.log('submit formdata', data);
        
        // onFormSubmit(formData);
        dispatch(submit(data));
    };

    useEffect(() => {
        // console.log('state: ', formState);
        const formErrors = formik.reduce((acc, item)=> {
            const element = formState[item.id];
            let touched = false;

            if (element || element === '') {
                touched = true;
            }

            return {
                ...acc,
                [item.id]: {
                    id: item.id,
                    touched,
                    error: item.required ? (!formState[item.id]) : false,
                }
            };
        }, {});

        // console.log('errrors', formErrors);

        setFormErrorsState(formErrors);

        if (Object.values(formErrors).some((prop)=> prop.error)) {
            setFormIsValid(false);
        }
        else {
            setFormIsValid(true);
        }

    }, [formState]);

    useEffect(() => {
        // console.log('success: ', success);
        if (success && shouldResetForm) {
            setShouldResetForm(false);
            setTimeout(() => {
                resetForm();
            }, 5000);
        }

    }, [success]);

    // console.log('formik', formik);

    const fields = formik.map((formItem) => {
        if (formItem.formType === 'input') {
            return (
                <InputField 
                    { ...formItem }
                    error={ formErrorsState[formItem.id] }
                    value={ formState[formItem.id] || '' }
                    onChange={ onChange }
                />
            );
        }
        else if (formItem.formType === 'textarea') {
            return (
                <TextareaField 
                    { ...formItem }
                    error={ formErrorsState[formItem.id] }
                    value={ formState[formItem.id] || '' }
                    onChange={ onChange }
                />
            );
        }

        return null;
    });

    // console.log('fields:', fields);

    const extraFields = formik.map((formItem) => {
        if (formItem.formType === 'checkbox') {
            return (
                <CheckboxField 
                    { ...formItem }
                    value={ formState[formItem.id] || false }
                    onChange={ onChange }
                />
            );
        }
        else if (formItem.formType === 'file') {
            return (
                <FileField 
                    { ...formItem }
                    value={ formState[formItem.id] || null }
                    onChange={ onChange }
                />
            );
        }

        return null;
    });

    const resetForm = () => {
        setFormState({});
        setFormErrorsState({});
        setFormIsValid(false);
        setShouldResetForm(true);
        dispatch(reset());
    };

    // console.log('extrafields:', extraFields);

    return (
        <form>
            <div className={ classNames(Styles.form, AppStyles.container) }>
                <div className={ Styles.box }>
                    <h2>
                        { title }
                    </h2>
                    <div className={ Styles.fields }>
                        { fields }
                    </div>
                    <div>
                        { extraFields }
                    </div>
                </div>
                <div className={ Styles.actions }>
                    <button
                        type="button"
                        className={ classNames(Styles.submit, { [Styles.saving]: saving, [Styles.success]: success }) }
                        disabled={ !formIsValid || saving || success }
                        onClick={ onClick }>
                        {
                            !saving && !success &&
                            'Enviar'
                        }
                        {
                            saving &&
                            'A Enviar...'
                        }
                        {
                            success &&
                            'Enviado!'
                        }
                    </button>
                </div>
            </div>
        </form>
    );
};

export default Form;