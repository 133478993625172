import { combineReducers } from 'redux';

import { SET_CONFIG, SET_COOKIES, TOGGLE_MENU } from './actions';

export const initialState = {
    menuOpen: false,
    config: {},
    cookies: false,
};

export const menuOpen = (state = initialState.menuOpen, action) => {
    switch (action.type) {
    case TOGGLE_MENU:
        return !state;
    default:
        return state;
    }
};

export const config = (state = initialState.config, action) => {
    switch (action.type) {
    case SET_CONFIG:
        // console.log('SET_CONFIG Action', action.payload);
        return action.payload;
    default:
        return state;
    }
};

export const cookies = (state = initialState.cookies, action) => {
    switch (action.type) {
    case SET_COOKIES:
        // console.log('SET_COOKIES Action', action.payload);
        return action.payload;
    default:
        return state;
    }
};

export default combineReducers({
    menuOpen,
    config,
    cookies,
});
