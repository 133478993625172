import React from 'react';
import classNames from 'classnames';
import { scroller, Element } from 'react-scroll';
import { useSelector } from 'react-redux';
import {
    useParams
} from 'react-router-dom';

import JobForm from '../../shared/components/forms/JobForm';

import { adaptJob } from '../../shared/helpers/adapters';

import IconJobsCheck from '../../images/icon_jobs_check.svg';

import Styles from './SinglePage.module.css';
import AppStyles from '../../App.module.css';

const JobDetail = ({ label, value, className }) => {
    return (
        <div className={ classNames(Styles.jobDetail, { [className]: className }) }>
            <div className={ Styles.label }>
                { label }
            </div>
            <div className={ Styles.value }>
                { value }
            </div>
        </div>
    );
};

const SinglePage = () => {
    let { slug } = useParams();

    const posts = useSelector(state => state.posts.data);
    const post = posts.find(item => item.slug === slug);
    let job = {};

    // console.log('post:', post);

    if (post) {
        job = adaptJob(post);
    }
    // else { //TODO remove before deploy live
    //     job = {
    //         id: 1,
    //         url: 'ccc',
    //         date: '22 Jan',
    //         status: 'Em aberto',
    //         role: 'Técnico Superior dos Serviços de Manutenção/Energia (m/f)',
    //         company: 'Sinapse RH Unipessoal, Lda',
    //         location: 'Coimbra (Miranda do Corvo)',
    //         area: 'Electricidade e Energia',
    //         startwork: 'Dezembro 2020',
    //         worktype: 'Full-time',
    //         money: '18,000€ - 32,000€ (Bruto anual)',
    //         content: 'texto da candidatura',
    //     };
    // }

    // console.log('job:', job);

    if (!job) {
        return null;
    }

    const goToForm = () => {
        scroller.scrollTo('jobs-form', {
            // duration: 1500,
            // delay: 100,
            smooth: true,
            // offset: 0, // Scrolls to element + 50 pixels down the page
        }); 
    };

    return (
        <div className={ classNames(Styles.job, AppStyles.container) }>
            <div className={ classNames(Styles.header, AppStyles.addMobilePadding) }>
                <div className={ Styles.image }>
                    <img src={ IconJobsCheck } alt=""/>
                </div>
                <div className={ Styles.title }>
                    <h1>
                        Oportunidade
                    </h1>
                </div>
                <div className={ Styles.apply }>
                    <a
                        role="button"
                        tabIndex="0"
                        onClick={ goToForm }>
                        Candidatar
                        <div className={ Styles.iconGo } />
                    </a>
                </div>
            </div>
            {/* <div className={ Styles.info }>
                <JobDetail label="Anúncio" value={ job.date } />
                <JobDetail label="Estado" value={ job.status } />
                <JobDetail label="Cargo" value={ job.role } />
            </div> */}
            <div className={ classNames(Styles.info, AppStyles.addMobilePadding) }>
                {job.role}
            </div>
            <div className={ Styles.divider } />
            <div className={ Styles.details }>
                <JobDetail className={ Styles.location } label="Localização" value={ job.location } />
                <JobDetail className={ Styles.area } label="Área" value={ job.area } />
                <JobDetail className={ Styles.company } label="Empresa" value={ job.company } />
                <JobDetail className={ Styles.startwork } label="Entrada" value={ job.startwork } />
                <JobDetail className={ Styles.worktype } label="Posição" value={ job.worktype } />
                <JobDetail className={ Styles.money } label="Remuneração" value={ job.money } />
            </div>
            <div className={ Styles.divider } />
            
            <div className={ classNames(Styles.content, AppStyles.addMobilePadding) }>
                <div dangerouslySetInnerHTML={{ __html: job.content }} />
                {/* <div>
                    <h1>
                    Sobre a Empresa
                    </h1>
                    <p>
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Excepturi deleniti blanditiis culpa dolorum id beatae necessitatibus, illo enim alias, quam accusantium voluptatem magni numquam dolores eveniet sapiente quod veritatis itaque.
                    </p>
                    <h2>
                        Responsabilidades
                    </h2>
                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque, asperiores voluptatem laudantium facere harum, beatae cumque aliquid excepturi nesciunt dolor voluptatibus iste minima hic illo porro repudiandae doloremque voluptates consectetur.
                    </p>
                    <ul>
                        <li>
                            1
                        </li>
                        <li>
                            2
                        </li>
                        <li>
                            3
                        </li>
                        <li>
                            4
                        </li>
                        <li>
                            5
                        </li>
                    </ul>
                </div> */}
            </div>

            <Element name="jobs-form" />
            <JobForm job={ job && job.role } />

            <div className={ classNames(Styles.disclaimer, AppStyles.addMobilePadding) }>
                <p className={ Styles.disclaimerTitle }>
                    POLÍTICA DE PRIVACIDADE DE DADOS
                </p>
                <p>
                    A Sinapse informa que no contexto de qualquer candidatura, seja ela espontânea ou em resposta a uma vaga em aberto, apenas proceder-se-á ao tratamento e análise dos dados estritamente necessários e relevantes à compreensão do grau de compatibilidade entre o perfil do candidato e a(s) oportunidade(s) de emprego em carteira. Isto significa que não é necessário ou desejável a inclusão de informação não relevante para a candidatura como é o caso da morada completa (basta a localidade), NIF, situação militar, estado civil ou quaisquer outras informações de carácter pessoal. Em linha com o Regulamento Geral de Proteção de Dados que irá entrar em vigor em Maio de 2018 a Sinapse recolherá apenas os dados relevantes para cada processo de candidatura e encontra-se ao inteiro dispor para explicar quaisquer questões que possam surgir neste âmbito.
                </p>
            </div>
        </div>
    );
};

export default SinglePage;