export const Routes = {
    HOME: '/',
    CONTACTS: '/#contactos',
    SERVICES: '/servicos',
    SERVICES_COMPANIES: '/servicos/empresas',
    SERVICES_PEOPLE: '/servicos/pessoas',
    TERMS: '/cookies',
    PRIVACY: '/privacidade',
    JOBS: '/candidaturas',
    JOBS_SINGLE: '/candidaturas/:slug',
};

export const Pages = {
    HOME: 'Início',
    COURSES: 'Cursos',
    EXAMS: 'Exames',
    SCHOOL: 'A Escola',
    FAQ: 'Questões frequentes',
    TERMS: 'Política de Cookies',
    PRIVACY: 'Política de Privacidade',
    NEWS: 'Notícias',
    NEWS_SINGLE: 'Notícias > {{title}}',
};

export const Breakpoints = {
    XLARGE: 1200,
    LARGE: 992,
    MEDIUM: 768,
    SMALL: 360,
};
