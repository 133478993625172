import React from 'react';
import { scroller } from 'react-scroll';
import classNames from 'classnames';
import {
    Link,
    useHistory,
} from 'react-router-dom';

import { Routes } from '../../../constants';

import IconPeople from '../../../images/icon_people.svg';
// import IconGoNormal from '../../../images/icon_go_normal.svg';

import Styles from './ServicesPeoplePage.module.css';
import AppStyles from '../../../App.module.css';

const ServicesPeoplePage = () => {
    let history = useHistory();

    function goToTarget(pageUrl, target, offset = 0) {
        console.log('goToTarget', pageUrl, target);
        history.push(pageUrl);
        setTimeout(() => {
            scroller.scrollTo(target, {
                // duration: 1500,
                // delay: 100,
                smooth: true,
                offset, // Scrolls to element + 50 pixels down the page
            }); 
        }, 100);
    }

    return (
        <div className={ classNames(Styles.services, AppStyles.container, AppStyles.addMobilePadding) }>
            <div className={ Styles.header }>
                <div className={ Styles.image }>
                    <img src={ IconPeople } alt=""/>
                </div>
                <div className={ Styles.title }>
                    <h1>
                        Pessoas
                    </h1>
                </div>
            </div>
            <p>
                O nosso compromisso para com as pessoas é de ética e respeito pela privacidade e dignidade de quem deposita em nós o seu tempo e expectativas em momentos cruciais para o seu percurso de vida.
            </p>

            <div className={ Styles.jobs }>
                <div className={ Styles.job }>
                    <div className={ Styles.textWrapper }>  
                        <div className={ Styles.text }>
                        Consulte a nossa lista de <br />
                            <span>Oportunidades de emprego</span>
                        </div>
                        <div className={ Styles.jobArrow }>
                        </div>
                    </div>
                    <a
                        role="button"
                        tabIndex="0"
                        onClick={ () => goToTarget(Routes.JOBS, 'jobs-table', -100) }>
                    </a>
                </div>

                <div className={ Styles.job }>
                    <div className={ Styles.textWrapper }>                    
                        <div className={ Styles.text }>
                        Faça a sua <br />
                            <span>Candidatura Espontânea</span>
                        </div>
                        <div className={ Styles.jobArrow }>
                        </div>
                    </div>
                    <a
                        role="button"
                        tabIndex="0"
                        onClick={ () => goToTarget(Routes.JOBS, 'jobs-spontaneous', -80) }>
                    </a>
                </div>
            </div>

            <h3>
                Gestão pessoal de competências
            </h3>
            <p>
                Avaliamos as competências comportamentais de jovens à procura do primeiro emprego e de pessoas que se encontram desempregadas ou que pretendem mudar de entidade laboral ou de projecto de carreira.
            </p>
            <p>
                A metodologia utilizada inclui entrevistas, avaliação da personalidade por um psicólogo que é membro efectivo da Ordem dos Psicólogos, e exercícios práticos.
            </p>
            <p>
                O feedback individual que facultamos permite uma visão ampla e integradora dos pontos fortes e das limitações de cada pessoa avaliada e, consequentemente, das suas necessidades de desenvolvimento, objetivos e planos de ação para o futuro.
            </p>
            <p>
                Apoiamos ainda na elaboração de CVs, incluindo a revisão linguística, e na preparação para entrevistas de seleção.
            </p>
        </div>
    );
};

export default ServicesPeoplePage;