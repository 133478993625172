import React from 'react';
import classNames from 'classnames';

import Styles from './ServicesBlocks.module.css';
import AppStyles from '../../../App.module.css';

const ServicesBlocks = ({ blocks, width360 = false, className }) => {
    return (
        <div className={ classNames(Styles.servicesBlocks, AppStyles.container, {  [className]: className }) }>
            <div className={ Styles.blocks }>
                <div className={ Styles.blocksHorizontal }>
                    {
                        blocks.map((block, index)=> (
                            <div key={index} className={ classNames(Styles.block, { [Styles.width360]: width360 }) }>
                                <p>
                                    { block.text }
                                </p>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
};

export default ServicesBlocks;
