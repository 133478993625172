import { combineReducers } from 'redux';
// import { reducer as toastrReducer } from 'react-redux-toastr';

import page from '../state/page/reducer';
import posts from '../state/posts/reducer';
import pages from '../state/pages/reducer';
import form from '../state/form/reducer';
// import auth from '../state/auth/reducer';


// import listPage from '../state/list/reducer';
// import deliveryTypes from '../state/delivery-types/reducer';
// import zipCodes from '../state/zip-codes/reducer';
// import pharmacies from '../state/pharmacies/reducer';

const rootReducer = combineReducers({
    // toastr: toastrReducer,
    page,
    pages,
    posts,
    form,
    // auth,
    // listPage,
    // deliveryTypes,
    // zipCodes,
    // pharmacies: listReducer,
});

export default rootReducer;
