import React from 'react';
import classNames from 'classnames';

import ServicesImage from '../../../images/servicos.png';

import Styles from './ServicesCard.module.css';
import AppStyles from '../../../App.module.css';

const ServicesCard = () => {
    return (
        <div className={ classNames(Styles.services, AppStyles.container) }>
            <div className={ Styles.header }>
                <div className={ Styles.image }>
                    <img src={ ServicesImage } alt=""/>
                </div>
                <div className={ Styles.description }>
                    <span>
                            Os nossos
                    </span>
                    <div>
                            Serviços
                    </div>
                </div>
            </div>
            <div className={ Styles.text }>
                <span>
                        Servimos <strong>Pessoas</strong> e <strong>Empresas</strong>  <br />
                        na procura por novos desafios.
                </span>
            </div>
        </div>
    );
};

export default ServicesCard;
