import React from 'react';
import classNames from 'classnames';
import {
    Link,
} from 'react-router-dom';

import SImage from '../../../images/s_monogram.png';

import Styles from './TheBrand.module.css';
import AppStyles from '../../../App.module.css';

const TheBrand = () => {
    return (
        <div className={ classNames(Styles.brand) }>
            <h1 className={ classNames(AppStyles.container, AppStyles.addMobilePadding) }>
                A Sinapse
            </h1>
            <div className={ classNames(Styles.wrapper) }>
                <div className={ classNames(Styles.box, AppStyles.container) }>
                    <div className={ Styles.logo }>
                        <img src={ SImage } alt=""/>
                    </div>
                    <div className={ Styles.text }>
                        <p className={ Styles.bigText }>
                        As pessoas, pelo conhecimento que geram, são o verdadeiro factor de vantagem competitiva das organizações: <br />
                            <span>o único capaz de aprender, mudar, evoluir, inovar e ensinar. </span>
                        </p>
                        <p className={ Styles.smallText }>
                        Acreditamos que podemos ajudar os nossos clientes a serem <span>organizações pensantes, vibrantes e proactivas</span>, contribuindo para soluções pensadas no quadro das circunstâncias específicas de cada processo de gestão das pessoas, sem nunca perder o foco na matriz cultural e estratégica da empresa.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TheBrand;