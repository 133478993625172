import React from 'react';
import classNames from 'classnames';
import {
    Link,
} from 'react-router-dom';
import { scroller } from 'react-scroll';

import Styles from './WhySinapse.module.css';
import AppStyles from '../../../App.module.css';

const WhySinapse = () => {

    function goToContacts() {
        setTimeout(() => {
            scroller.scrollTo('contacts', {
                // duration: 1500,
                // delay: 100,
                smooth: true,
                offset: -60, // Scrolls to element + 50 pixels down the page
            }); 
        }, 100);
    }

    return (
        <div className={ Styles.wrapper }>
            <div className={ classNames(Styles.why, AppStyles.container) }>
                <h1>
                Porquê a Sinapse?
                </h1>
                <div className={ Styles.box }>
                    <div className={ Styles.blocks }>
                        <div className={ Styles.item }>
                            <h4>
                            Conhecimento
                            especializado
                            </h4>
                            <p>
                        Porque o seu negócio é ainda uma start-up e/ou não possui o conhecimento especializado para procurar as pessoas certas para alavancar o crescimento da sua empresa.
                            </p>
                        </div>
                        <div className={ Styles.item }>
                            <h4>
                            Tempo
                            </h4>
                            <p>
                        Porque consumindo muitos recursos, principalmente de tempo, prefere entregar o processo a um serviço especializado libertando-o para outras tarefas urgentes.
                            </p>
                        </div>
                        <div className={ Styles.item }>
                            <h4>
                            Personalidade e <br/>
                            soft-skills
                            </h4>
                            <p>
                        Porque chegou à conclusão de que tão (ou mais) importante do que as competências técnicas é fundamental ter uma análise aprofundada do perfil de personalidade e de soft skills dos candidatos.
                            </p>
                        </div>
                    </div>
                    <div className={ Styles.callout }>
                    Gostaria de trabalhar connosco?
                        <a
                            role="button"
                            tabIndex="0"
                            onClick={ goToContacts }>
                        Contacte-nos
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WhySinapse;