import React from 'react';
import {
    Link,
} from 'react-router-dom';

import { Routes } from '../../../constants';

import Form from './Form';

const SpontaneousJobForm = () => {
    const formik = [
        {
            id: 'name',
            label: 'Nome',
            placeholder: 'Insira o seu nome completo',
            formType: 'input',
            type: 'text',
            required: true,
        },
        {
            id: 'email',
            label: 'Email',
            placeholder: 'Insira o seu email',
            formType: 'input',
            type: 'text',
            required: true,
        },
        {
            id: 'phone',
            label: 'Telefone',
            placeholder: 'Insira o seu número de contacto',
            formType: 'input',
            type: 'number',
            required: true,
        },
        {
            id: 'location',
            label: 'Localização',
            placeholder: 'Insira a sua localização',
            formType: 'input',
            type: 'text',
            required: true,
        },
        {
            id: 'company-role',
            label: 'Função / Área pretendida',
            placeholder: 'Descreva a função ou área pretendida',
            formType: 'input',
            type: 'text',
            required: true,
            size: 'full',
        },
        {
            id: 'message',
            label: 'Carta de Apresentação',
            placeholder: 'Escreva a sua mensagem...',
            formType: 'textarea',
            type: 'text',
            required: true,
        },
        {
            id: 'terms',
            label: (
                <>
                    {
                        'Aceito os '
                    }
                    <Link to={ Routes.TERMS } target="_blank">Termos e Política de Privacidade</Link>
                </>
            ),
            formType: 'checkbox',
            type: 'checkbox',
            required: true,
        },
        {
            id: 'file',
            placeholder: 'Anexar CV',
            formType: 'file',
            type: 'file',
            required: true,
        },
    ];

    return (
        <>
            <Form
                title="Candidaturas Espontâneas"
                job="Candidatura Espontânea"
                formik={ formik }/>
        </>
    );
};

export default SpontaneousJobForm;