import urlJoin from 'url-join';
import { Routes } from '../../constants';

export const adaptJob = (job) => ({
    id: job.id,
    url: urlJoin(Routes.JOBS, job.slug),
    // date: job.acf.date,
    // status: job.acf.status.length && job.acf.status[0],
    role: job.acf.role,
    company: job.acf.company,
    location: job.acf.location,
    area: job.acf.area,
    startwork: job.acf.startwork,
    worktype: job.acf.worktype.length && job.acf.worktype[0],
    money: job.acf.money,
    content: job.content.rendered,
});

export const adaptPage = (page) => ({
    id: page.id,
    wpSlug: page.slug,
    title: page.title.rendered,
    seoTitle: page.acf.seo_title,
    seoDescription: page.acf.seo_description,
    slug: page.acf.slug,
    content: page.content.rendered,
});

// export const adaptNews = (news) => {
//     return news.map((item)=>({
//         id: item.id,
//         title: item.title.rendered,
//         date: item.date,
//         url: urlJoin(Routes.JOBS, item.slug)
//     }));
// };

// export const adaptPages = (pages) => {
//     return pages.map((item)=>({
//         id: item.id,
//         seoTitle: item.acf.seo_title,
//         seoDescription: item.acf.seo_description,
//         slug: item.acf.slug
//     }));
// };