import buildUrl from 'build-url';
import deepcompact from 'deep-compact';
import axios from 'axios';

import { actions } from './reducer';

const RESOURCE_URL = '/wp-json/wp/v2/pages';

export const get = (page = 1, slug = '') => (dispatch, getState) => {
    dispatch(actions.fetchStart());

    const baseUrl = getState().page.config.cms;
    const url = buildUrl(baseUrl, {
        path: RESOURCE_URL,
        // hash: 'contact',
        queryParams: deepcompact({
            page,
            slug,
        }),
    });

    axios.get(url)
        .then(response => response.data)
        .then(data => {
            // console.log('data', data);
            dispatch(actions.fetchSuccess(data));
        })
        .catch(error => {
            // handle error
            console.error(error);
            dispatch(actions.fetchFail(error.toString()));
        });
};
