import buildUrl from 'build-url';
import axios from 'axios';

const namespace = 'form';

export const SAVE_START = `${namespace}/SAVE_START`;
export const SAVE_SUCCESS = `${namespace}/SAVE_SUCCESS`;
export const SAVE_FAIL = `${namespace}/SAVE_FAIL`;

export const RESET = `${namespace}/RESET`;

const RESOURCE_URL = '/wp-json/contact-form-7/v1/contact-forms/5/feedback';

export const submit = (formData) => (dispatch, getState) => {
    dispatch({
        type: SAVE_START,
    });

    const baseUrl = getState().page.config.cms;
    const url = buildUrl(baseUrl, {
        path: RESOURCE_URL,
    });

    // const form = new FormData();
    // bodyFormData.set('userName', 'Fred');
    // bodyFormData.append('image', imageFile);

    axios({
        method: 'post',
        url,
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
    })
        .then((response) => {
            //handle success
            console.log(response);
            if (response.status === 200 || response.data.status === 'mail_sent') {
                dispatch({
                    type: SAVE_SUCCESS,
                });
            }
            else {
                dispatch({
                    type: SAVE_FAIL,
                });
                alert('Erro a enviar o formulário');
            }
        })
        .catch((response) => {
            //handle error
            console.log(response);
            dispatch({
                type: SAVE_FAIL,
            });
        });
    
};

export const reset = () => (dispatch) => {
    dispatch({
        type: RESET,
    });
};
