import React from 'react';
import {
    Link,
} from 'react-router-dom';

import { Routes } from '../../../constants';

import Form from './Form';

const CompanyForm = () => {
    const formik = [
        {
            id: 'name',
            label: 'Nome',
            placeholder: 'Insira o seu nome completo',
            formType: 'input',
            type: 'text',
            required: true,
        },
        {
            id: 'email',
            label: 'Email',
            placeholder: 'Insira o seu email',
            formType: 'input',
            type: 'text',
            required: true,
        },
        {
            id: 'company',
            label: 'Empresa',
            placeholder: 'Insira o nome da empresa',
            formType: 'input',
            type: 'text',
            required: true,
        },
        {
            id: 'company-role',
            label: 'Função na empresa',
            placeholder: 'Insira a sua função',
            formType: 'input',
            type: 'text',
            required: true,
        },
        {
            id: 'subject',
            label: 'Assunto',
            placeholder: 'Descreva o assunto',
            formType: 'input',
            type: 'text',
            required: true,
            size: 'full',
        },
        {
            id: 'message',
            label: 'Mensagem',
            placeholder: 'Escreva a sua mensagem...',
            formType: 'textarea',
            type: 'text',
            required: true,
        },
        {
            id: 'terms',
            label: (
                <>
                    {
                        'Aceito os '
                    }
                    <Link to={ Routes.TERMS } target="_blank">Termos e Política de Privacidade</Link>
                </>
            ),
            formType: 'checkbox',
            type: 'checkbox',
            required: true,
        },
    ];

    return (
        <>
            <Form
                title="Trabalhe connosco"
                formik={ formik }/>
        </>
    );
};

export default CompanyForm;