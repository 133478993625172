import React from 'react';
import classNames from 'classnames';

import Styles from './Terms.module.css';
import AppStyles from '../../App.module.css';

const PrivacyPage = () => {
    return (
        <div className={ classNames(Styles.terms, AppStyles.container, AppStyles.addMobilePadding) }>
            <h3>
                Política de Privacidade e Protecção de Dados
            </h3>
            <p className={Styles.subTitle}>
                Sinapse. Consultores em Recursos HumanosⓇ é uma marca registada por mim, Fernando José Salema Monteiro Casimiro, profissional liberal autónomo, que usufruo da mesma para efeitos estritamente publicitários.
            </p>
            <p className={Styles.message}>
                Eu e/ou qualquer outro consultor que trabalhe no mesmo regime de profissional liberal sob a marca Sinapse. Consultores em Recursos Humanos, estamos e estaremos sempre absolutamente empenhados em respeitar a privacidade e protecção dos dados de candidatos, clientes e quaisquer outras partes interessadas com as quais possamos manter algum tipo de relação profissional, em linha com o escrupuloso cumprimento do Regulamento Geral de Proteção de Dados (RGPD) Regulamento (UE) 2016/679, de 27 de abril de 2016.
            </p>

            <p>
                <strong>Que tipos de dados pessoais recolhemos?</strong> <br />
                Os dados pessoais recolhidos e guardados na nossa base de dados são aqueles estritamente necessários no âmbito dos serviços que prestamos: <br />
                Nome <br />
                Localidade <br />
                Data de nascimento <br />
                Dados de contacto (e-mail, telemóvel) <br />
                Trajecto académico <br />
                Trajecto profissional <br />
                Competências <br />
                Outros dados adicionais relevantes contidos no cv
            </p>
            <p>
                <strong>Como fazemos o tratamento dos dados recolhidos?</strong> <br />
                Os dados por nós recolhidos são tratados e processados no sentido da criação de um perfil tendo por objectivo avaliar e aferir da adequabilidade e compatibilidade dos candidatos com a função que os nossos clientes pretendem preencher. <br />
                A análise e avaliação de dados com vista à criação de um perfil, bem como a tomada de decisão, são processos realizados exclusivamente por humanos. Se, no futuro, for integrada qualquer tecnologia de automatização de processamento de dados, os candidatos, ou quaisquer outras partes interessadas, serão informados e deverá ser recolhido o seu consentimento para este tipo de processamento de dados.
            </p>
            <p>
                <strong>Finalidade</strong> <br />
                A recolha e tratamento de dados de candidatos, clientes ou quaisquer outras partes interessadas, refere-se a uma utilização exclusiva no âmbito dos serviços que prestamos, especificamente no contexto de recrutamento e selecção (ou serviços afins). <br />
                Não utilizamos os seus dados para quaisquer outras finalidades, como por exemplo, marketing.
            </p>
            <p>
                <strong>Segurança no processo de manutenção dos dados</strong> <br />
                Tomamos todas as diligências necessárias para garantir a segurança dos dados de todas as partes interessadas por nós recolhidos. <br />
                Os dados recolhidos e tratados são guardados em ambiente de “cloud” providenciado pelos serviços especializados da Google para o efeito, com a utilização de autenticação de múltiplos passos no momento de acesso a esses mesmos dados.
            </p>
            <p>
                <strong>Quem acede aos dados?</strong> <br />
                Os dados pessoais são acedidos apenas por mim, Fernando José Salema Monteiro Casimiro, ou por outros consultores-parceiros que estejam a desenvolver algum processo de recrutamento e selecção. Nesta situação, o consultor assinará uma declaração de responsabilidade e compromisso com o exposto neste documento.
            </p>
            <p>
                <strong>Partilha de dados com terceiros</strong> <br />
                Os dados recolhidos e tratados de candidatos poderão/serão partilhados com os nossos clientes que contratam os nossos serviços. Essa partilha será feita no exclusivo âmbito dos serviços contratados e terá a função natural inerente a este tipo de processos no sentido de haver uma tomada de decisão por parte do cliente quanto à escolha do(s) candidato(s) a integrar a organização.
            </p>
            <p>
                <strong>Consentimento informado</strong> <br />
                Sempre que haja espaço neste site para o envio de dados pessoais, será obrigatório que os candidatos/clientes dêem o seu consentimento, através de uma caixa de verificação, para a recolha e tratamento de dados e para que sejam contactados no âmbito dos serviços que desenvolvemos, naquilo que constitui uma aceitação da presente Política de Privacidade apresentada nesta mesma página.
            </p>
            <p>
                Pediremos também o consentimento aos candidatos não selecionados mas dos quais, por motivos de potencial para enquadramento em processos futuros, desejamos manter os dados pessoais na nossa base de dados. Neste caso, o consentimento será pedido por e-mail.
            </p>
            <p>
                Pediremos também o consentimento para a recolha e tratamento de dados no caso de candidaturas espontâneas.
            </p>
            <p>
                Sempre que um anúncio de emprego seja publicado numa plataforma de anúncios de emprego pública, apresentaremos a seguinte chamada para a política de protecção de dados: <br />
                “Informamos que, ao enviar o seu CV, está a aceitar a nossa Política de Privacidade e a autorizar o nosso contacto para esclarecimento dos dados fornecidos. Poderá consultar a nossa Política de Privacidade e Protecção de Dados no seguinte endereço: xxxxxxxxxx (remetendo para esta mesma página)”.
            </p>
            <p>
                Há uma situação de excepção para o “consentimento informado” que está associada ao facto de haver portais de emprego que partilham, por rotinas automáticas, os anúncios de emprego localizados noutros portais. Trata-se de algo ao qual sou alheio, pelo que não é garantido que haja lugar ao consentimento informado nesse tipo de candidaturas, sendo que  a aplicação da Política de Privacidade será, naturalmente, também ela estritamente aplicada.
            </p>
            <p>
                <strong>Período de conservação dos dados</strong> <br />
                Os dados só poderão ser conservados se estiverem actualizados. <br />
                Os dados pessoais dos candidatos serão mantidos em base de dados pelo período estritamente necessário à conclusão dos processos de recrutamento e selecção (ou processos afins). Após este período, todos os dados que possuímos dos candidatos serão eliminados. <br />
                No caso de candidaturas espontâneas ou de conservação de dados de candidatos não selecionados mas com potencial para integrar processos futuros, considera-se que os dados estão desatualizados ao fim de um ano da sua recolha (de acordo com a Comissão Nacional de Protecção de dados), pelo que, após este período (1 ano),  as informações constantes na base de dados serão eliminadas.
            </p>
            <p>
                <strong>Direitos relativamente aos seus dados</strong> <br />
                Mesmo que já estejamos na posse dos seus dados pessoais, o titular dos dados continua a ter vários direitos em relação aos mesmos.
            </p>
            <p>
                <strong>Direito de Acesso do titular dos dados:</strong> O titular dos dados tem o direito de obter a confirmação, do responsável pelo tratamento, de que os dados pessoais que lhe digam respeito são ou não objecto de tratamento e, se for esse o caso, o direito de aceder aos seus dados pessoais e a outras informações, como, por exemplo, a finalidade do tratamento, as categorias dos dados pessoais em questão, os destinatários a quem os dados foram ou serão divulgados, o prazo previsto de conservação, entre outros.
            </p>
            <p>
                <strong>Direito de Retificação:</strong> O titular tem o direito de obter, sem demora injustificada, do responsável pelo tratamento a retificação dos dados pessoais inexatos que lhe digam respeito. Tendo em conta as finalidades do tratamento, o titular dos dados tem direito a que os seus dados pessoais incompletos sejam completados, incluindo por meio de uma declaração adicional.
            </p>
            <p>
                <strong>Direito a ser Esquecido:</strong> O titular tem o direito de obter do responsável pelo tratamento o apagamento dos seus dados pessoais, sem demora injustificada, e este tem a obrigação de apagar os dados pessoais, sem demora injustificada, de acordo com os motivos previstos no n.º 1 do artigo 17 do actual Regulamento Geral de Proteção de Dados (RGPD).
            </p>
            <p>
                <strong>Direito à limitação do Tratamento:</strong> O titular dos dados tem o direito de obter do responsável pelo tratamento a limitação do tratamento, por exemplo, em situações em que haja inexatidão dos dados, ou situações em que o tratamento é ilícito e o titular dos dados se opõe ao apagamento dos dados pessoais.
            </p>
            <p>
                <strong>Direito de Portabilidade dos dados:</strong> O titular dos dados tem o direito de receber os dados pessoais que lhe digam respeito e que tenha fornecido a um responsável pelo tratamento, num formato estruturado, de uso corrente e de leitura automática, assim como o direito de transmitir esses dados a outro responsável pelo tratamento sem que o responsável a quem os dados pessoais foram fornecidos o possa impedir, se o tratamento se basear no consentimento ou for realizado por meios automatizados.
            </p>
            <p>
                <strong>Direito de Oposição:</strong> O titular dos dados tem o direito de se opor, a qualquer momento, por motivos relacionados com a sua situação particular, ao tratamento dos dados pessoais que lhe digam respeito. O responsável pelo tratamento cessa o tratamento dos dados pessoais, a não ser que apresente razões imperiosas e legítimas para esse tratamento que prevaleçam sobre os interesses, direitos e liberdades do titular dos dados, ou para efeitos de declaração, exercício ou defesa de um direito num processo judicial.
            </p>
            <p>
                Eu, Fernando Casimiro, sou o responsável pelo tratamento dos dados, pelo que, em caso de dúvida ou necessidade de esclarecimentos adicionais, deverá entrar em contacto através dos seguintes meios:
                fernando.salema.casimiro@sinapserh.pt
                91 99 00 4 88
            </p>
        </div>
    );
};

export default PrivacyPage;