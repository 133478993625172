import React from 'react';
import classNames from 'classnames';
import {
    Link,
} from 'react-router-dom';

import { Routes } from '../../../constants';

import ServicesImage from '../../../images/servicos.png';

import Styles from './Services.module.css';
import AppStyles from '../../../App.module.css';

const Services = () => {
    return (
        <div className={ classNames(Styles.services, AppStyles.container) }>
            <div className={ Styles.header }>
                <div className={ Styles.image }>
                    <img src={ ServicesImage } alt=""/>
                </div>
                <div className={ Styles.description }>
                    <span>
                            Os nossos
                    </span>
                    <div>
                            Serviços
                    </div>
                </div>
            </div>
            <div className={ Styles.links }>
                <div className={ Styles.link }>
                    <Link 
                        to={Routes.SERVICES_COMPANIES}>
                        <span>Empresas</span>
                        <div className={ Styles.linkIcon }></div>
                    </Link>
                </div>
                <div className={ Styles.link }>
                    <Link 
                        to={Routes.SERVICES_PEOPLE}>
                        <span>Pessoas</span>
                        <div className={ Styles.linkIcon }></div>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Services;