import React from 'react';
import { scroller } from 'react-scroll';
import classNames from 'classnames';
import {
    Link,
    useHistory,
} from 'react-router-dom';

import IconPeople from '../../../images/icon_people.svg';
import IconCompany from '../../../images/icon_company.svg';
import IconGoOrange from '../../../images/icon_go_orange.svg';

import Styles from './ServicesInformation.module.css';
import AppStyles from '../../../App.module.css';
import { Routes } from '../../../constants';

const ServicesInformation = ({ title, knowMoreUrl, blocks, children }) => {
    let history = useHistory();

    function goToTarget(pageUrl, target) {
        console.log('goToTarget', pageUrl, target);
        history.push(pageUrl);
        setTimeout(() => {
            scroller.scrollTo(target, {
                // duration: 1500,
                // delay: 100,
                smooth: true,
                offset: -130, // Scrolls to element + 50 pixels down the page
            }); 
        }, 100);
    }

    return (
        <div className={ classNames(Styles.services, AppStyles.container) }>
            <div className={ Styles.header }>
                <div className={ Styles.image }>
                    {
                        knowMoreUrl === Routes.SERVICES_COMPANIES &&
                        <img src={ IconCompany } alt=""/>
                    }
                    {
                        knowMoreUrl === Routes.SERVICES_PEOPLE &&
                        <img src={ IconPeople } alt=""/>
                    }
                </div>
                <div className={ Styles.title }>
                    <h1>
                        { title }
                    </h1>
                </div>
                <div className={ Styles.knowMore }>
                    <Link to={knowMoreUrl}>
                        Saber mais
                    </Link>
                </div>
            </div>
            <div className={ Styles.text }>
                { children }
            </div>
            <div className={ Styles.blocks }>
                <div className={ Styles.blocksHorizontal }>
                    {
                        blocks.map((block, index)=> (
                            <div key={index} className={ classNames(Styles.block, { [Styles.width320]: title === 'Pessoas' }) }>
                                <h4>
                                    { block.title }
                                </h4>
                                <div className={ Styles.blockArrow }>
                                    <img src={ IconGoOrange } alt=""/>
                                </div>
                                <a
                                    role="button"
                                    tabIndex="0"
                                    onClick={ () => goToTarget(knowMoreUrl, block.target) }>
                                </a>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
};

export default ServicesInformation;
