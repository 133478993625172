import React from 'react';
import {
    Switch,
    Route,
} from 'react-router-dom';

import { Routes } from './constants';
import HomePage from './pages/home/HomePage';
import TermsPage from './pages/terms-privacy/TermsPage';
import PrivacyPage from './pages/terms-privacy/PrivacyPage';
import ServicesPage from './pages/services/ServicesPage';
import ServicesCompaniesPage from './pages/services/companies/ServicesCompaniesPage';
import ServicesPeoplePage from './pages/services/people/ServicesPeoplePage';
import JobsListPage from './pages/jobs/ListPage';
import JobsSinglePage from './pages/jobs/SinglePage';

const Routing = () => {
    return (
        <Switch>
            {/* <div className="App">
            <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <p>
                Edit 1 <code>src/App.js</code> and save to reload.
            </p>
            <a
                className="App-link"
                href="https://reactjs.org"
                target="_blank"
                rel="noopener noreferrer"
            >
                Learn React
            </a>
            </header>
        </div> */}
            <Route exact path={Routes.HOME}>
                <HomePage />
            </Route>
            <Route path={Routes.SERVICES_COMPANIES}>
                <ServicesCompaniesPage />
            </Route>
            <Route path={Routes.SERVICES_PEOPLE}>
                <ServicesPeoplePage />
            </Route>
            <Route path={Routes.SERVICES}>
                <ServicesPage />
            </Route>
            <Route path={Routes.TERMS}>
                <TermsPage />
            </Route>
            <Route path={Routes.PRIVACY}>
                <PrivacyPage />
            </Route>
            <Route path={Routes.JOBS_SINGLE}>
                <JobsSinglePage />
            </Route>
            <Route path={Routes.JOBS}>
                <JobsListPage />
            </Route>
            <Route>
                <div>
                    Error 404
                </div>
            </Route>
        </Switch>
    );
};

export default Routing;