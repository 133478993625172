import React  from 'react';
// import { useMedia } from 'react-use-media';
import { useWindowScroll } from 'react-use';
import {
    Link,
    useLocation
} from 'react-router-dom';
import classNames from 'classnames';
// import Sticky from 'react-stickynode';
import { animateScroll } from 'react-scroll';
import { useMedia } from 'react-use-media';
import { useDispatch } from 'react-redux';

import Seo from './Seo';

import { Routes, Breakpoints } from '../../../constants';

import { toggleMenu } from '../../state/page/actions';

import TopNav from './nav/TopNav';
import Breadcrumbs from '../breadcrumbs/Breadcrumbs';
// import MobileTopNav from './nav/MobileTopNav';

import LogoImage from '../../../images/logotipo_branco.png';
import MenuOpen from '../../../images/hamburger.svg';
// import LogoImageMobile from '../../../images/logotipo_branco.png';

import Styles from './Header.module.css';
import AppStyles from '../../../App.module.css';

const Header = () => {
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    // const [showMenu, setShowMenu] = useState(false);

    const { x, y } = useWindowScroll();

    const mobile = useMedia({
        maxWidth: Breakpoints.MEDIUM,
    });

    const onHomeClick = () => {
        if (pathname === Routes.HOME) {
            animateScroll.scrollToTop();
        }
    };

    return (
        <>
            <Seo />
            <div className={ classNames(Styles.headerWrapper, { 'sticky': y > 0 }) }>
                {/* <Sticky> */}
                <div className={ Styles.headerLine }>
                    <div className={ classNames(Styles.header, AppStyles.container) }>
                        <div className={ Styles.logo }>
                            <Link to={Routes.HOME} onClick={ onHomeClick }>
                                {/* {
                                        !mobile &&
                                    }
                                    {
                                        mobile &&
                                        <img src={ LogoImageMobile } alt="Logotipo Sinapse"/>
                                    } */}
                                <img src={ LogoImage } alt="Logotipo Sinapse"/>
                            </Link>
                        </div>

                        

                        {
                            !mobile &&
                            <div className={ Styles.navigation }>
                                <TopNav />
                            </div>
                        }

                        {
                            mobile &&
                            <div className={ Styles.navigation }>
                                <a
                                    onClick={ () => {dispatch(toggleMenu()); console.log('show menu');} }>
                                    <img src={ MenuOpen } alt=""/>
                                </a>
                            </div>
                        }
                    
                    </div>
                </div>
                {
                    y > 0 &&
                    <div className={ Styles.headerBlank }/>
                }
                {/* </Sticky> */}
                
                {
                    pathname === Routes.HOME &&
                    <div className={ Styles.banner }>
                        <div className={ AppStyles.container }>
                            <div className={ Styles.bannerTitle }>
                                <span>Consultoria</span> em <span>Recursos Humanos</span>
                            </div>
                            <div className={ Styles.bannerSubtitle }>
                                <p>
                                A Inteligência que cresce com o seu negócio
                                </p>
                            </div>
                        </div>
                    </div>
                }

                {
                    pathname !== Routes.HOME &&
                    <Breadcrumbs />
                }
            </div>

            
        </>
    );
};

export default Header;