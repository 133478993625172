import React from 'react';
import {
    Link,
} from 'react-router-dom';
import classNames from 'classnames';

import BottomNav from './nav/BottomNav';

import { Routes } from '../../../constants';

import Styles from './Footer.module.css';
import AppStyles from '../../../App.module.css';

const Footer = () => {
    return (
        <div className={ classNames(Styles.footer, AppStyles.container) }>
            <div className={ Styles.copyright }>
                <strong>Sinapse RH</strong> &copy; Copyright <br/>
                Todos os direitos reservados
            </div>
            
            <div className={ Styles.navigation }>
                <BottomNav />
            </div>

            <div className={ Styles.terms }>
                <ul>
                    <li>
                        <Link to={Routes.TERMS}>
                                Cookies
                        </Link>
                    </li>
                    <li>
                        |
                    </li>
                    <li>
                        <Link to={Routes.PRIVACY}>
                            Política de Privacidade
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Footer;