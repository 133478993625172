import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Header from './Header';
import Footer from './Footer';
import MobileTopNav from './nav/MobileTopNav';

import { toggleMenu } from '../../state/page/actions';

const Layout = ({ children }) => {
    const dispatch = useDispatch();
    const menuOpen = useSelector(state => state.page.menuOpen);

    return (
        <>
            <Header />

            { children }

            <Footer />
            
            {
                menuOpen &&
                <MobileTopNav
                    onClose={ () => { dispatch(toggleMenu()); console.log('hide menu'); }} />
            }
        </>
    );
};

export default Layout;