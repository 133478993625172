import React from 'react';
import { Element } from 'react-scroll';
import classNames from 'classnames';

import CompanyForm from '../../../shared/components/forms/CompanyForm';
import ServicesBlocks from '../../../shared/components/services-blocks/ServicesBlocks';

import IconCompany from '../../../images/icon_company.svg';

import Styles from './ServicesCompaniesPage.module.css';
import AppStyles from '../../../App.module.css';

const ServicesCompaniesPage = () => {
    return (
        <div className={ classNames(Styles.services, AppStyles.container) }>
            <div className={ AppStyles.addMobilePadding }>
                <div className={ Styles.header }>
                    <div className={ Styles.image }>
                        <img src={ IconCompany } alt=""/>
                    </div>
                    <div className={ Styles.title }>
                        <h1>
                        Empresas
                        </h1>
                    </div>
                </div>

                <p>
                Os nossos serviços representam soluções flexíveis de resposta para os problemas de Talento do seu negócio. Flexíveis porque mais do que divisões artificiais elas são co-construídas em parceria com o Cliente promovendo-se os vectores da Cultura Organizacional, Transformação e Conhecimento. 
                </p>

                <Element name="executive-search" />
                <h3>
                    <span>1.</span>
                Executive Search
                </h3>
                <p>
                O principal foco da Sinapse é a identificação de talento para funções estratégicas: posições de gestão de topo e chefia intermédia, com grande impacto organizacional, ou de especialistas, com grandes especificidades técnicas, mediante extensas operações de pesquisa do mercado alvo.
                </p>
                <div className={ Styles.subSection }>
                    <h4>
                    Metodologia
                    </h4>
                    <p>
                Através de um processo ancorado no conhecimento exaustivo da Cultura Organizacional da empresa cliente e na avaliação rigorosa do perfil psicológico de cada candidato, garantimos um aconselhamento permanente dos intervenientes para que sejam encontradas as melhores soluções ao nível da estratégia e dos processos de mudança da organização, e em termos do projeto pessoal de carreira do candidato.
                    </p>
                    <p>
                Isso implica frequentemente ajudar o Cliente a perceber se e quando é pertinente transcender o fit inicialmente definido, e calibrar o perfil pretendido, de modo a antecipar necessidades futuras.
                    </p>
                    <p>
                Tendo por base uma relação de parceria com o nosso Cliente, garantimos:
                    </p>

                    <ul>
                        <li>
                    Manutenção de um canal de comunicação em aberto que permita uma intervenção flexível do perfil mantendo em aberto qualquer ajustamento necessário;
                        </li>
                        <li>
                    Rigorosa confidencialidade de toda a informação cedida por clientes e potenciais colaboradores;
                        </li>
                        <li>
                    Informação prestada aos potenciais colaboradores nas condições aprovadas pelo Cliente;
                        </li>
                        <li>
                    O princípio ético de não recrutar colaboradores colocados no cliente através dos nossos serviços, enquanto se mantiver o vínculo contratual e “Off limits” por período de 2 anos;
                        </li>
                        <li>
                    Repetimos o processo de Recrutamento e Selecção sem encargos adicionais, no caso do candidato admitido para a função não completar o período de seis meses de trabalho devido a inadaptação.
                        </li>
                    </ul>
                </div>

                <Element name="recruitment-selection" />
                <h3>
                    <span>2.</span>
                Recrutamento e Seleção
                </h3>
                <p>
                Acreditamos que somos parceiros dos clientes na construção e transformação de organizações melhores. É por isso que, para nós, encontrar e seleccionar talento significa não só garantir o desempenho de hoje mas também que a Organização contará com pessoas envolvidas em torno de Um Projecto Partilhado. 
                </p>
                <div className={ Styles.subSection }>
                    <h4>
               Metodologia
                    </h4>
                    <p>
                Nenhum recrutamento começa sem que haja uma rigorosa avaliação de diversos parâmetros da organização que nos permita conhecer o negócio e a cultura da organização cliente e compreender os objetivos de uma nova contratação.
                    </p>
                    <p>
                Estabelecemos um canal de comunicação aberto com o cliente para partilha constante de informação, que permita uma adequada compreensão do perfil pretendido e eventual ajustamento daquilo que foi inicialmente definido, tendo em conta as contingências e variáveis do mercado de trabalho onde estamos a operar.
                    </p>
                    <p>
                Activamos então um conjunto de ferramentas ajustadas ao público alvo do nosso recrutamento, nomeadamente publicação de anúncio nas plataformas digitais relevantes de emprego, entre as quais, a área das Oportunidades do nosso site www.sinapserh.pt, e pesquisa em redes sociais/profissionais.
                    </p>
                    <p>
                Em paralelo, pesquisamos, na nossa base de dados, profissionais com experiência em funções similares em empresas do mesmo setor de atividade ou áreas de negócio idênticas.
                    </p>
                    <p>
                Após triagem curricular, seleccionamos os candidatos para entrevista presencial (ou via Skype, quando não for possível por questões geográficas), no sentido de explorarmos o seu trajecto profissional e traçar um perfil psicológico.
                    </p>
                    <p>
                Quando considerada pertinente, a avaliação psicológica é passo lógico seguinte ao proporcionar uma camada adicional de informação que poderá ser decisiva para garantir que encontramos talento que se encaixa no perfil da organização previamente definido.
                    </p>
                    <p>
                Identificados os perfis mais ajustados, apresentamos ao cliente uma shortlist de candidatos, sob a forma de relatórios individuais, concisos e objectivos.
                    </p>
                    <p>
                Ficamos disponíveis para participar activamente na etapa de entrevistas com o cliente, de modo a contribuir para uma cuidada transmissão de feedback e gestão de expectativas.
                Repetimos o processo de Recrutamento e Selecção sem encargos adicionais, no caso do candidato admitido para a função não completar o período de seis meses de trabalho devido a inadaptação.
                    </p>
                </div>

                <Element name="assessment" />
                <h3>
                    <span>3.</span>
                Assessment / Avaliação Psicológica
                </h3>
                <p>
                Muitas empresas que necessitam de recrutar e seleccionar, identificam candidatos com um perfil potencialmente adequado, mas não possuem especialistas para avaliar dimensões fundamentais que permitem traçar um desenho completo de cada candidato.
                </p>
                <p>
                Na Sinapse possuímos os testes de avaliação psicológica e o conhecimento especializado de psicólogos devidamente credenciados, para avaliar:
                </p>
                <div className={ Styles.subSection }>
                    <ServicesBlocks
                        className={Styles.blocksBold}
                        pClass={Styles.blocksText}
                        bold
                        blocks={[
                            {
                                text: 'Competências',
                            },
                            {
                                text: 'Motivações',
                            },
                            {
                                text: 'Personalidade',
                            },
                            {
                                text: 'Inteligência',
                            }
                        ]} />

                    <h5>
                    O serviço de Avaliação Psicológica tem os seguintes enquadramentos:
                    </h5>

                    <ServicesBlocks
                        className={Styles.blocksText}
                        width360
                        blocks={[
                            {
                                text: 'Avaliação Psicológica em processos de recrutamento & selecção sempre que seja identificada, em conjunto com o Cliente, a necessidade de avaliar dimensões críticas do funcionamento psicológico dos candidatos.',
                            },
                            {
                                text: 'Avaliação psicológica de actuais colaboradores, no sentido de identificar as pessoas com potencial de desenvolvimento para novos enquadramentos organizacionais.',
                            },
                            {
                                text: 'Avaliação psicológica de candidatos exteriores à organização que são previamente seleccionados pelo cliente.',
                            }
                        ]} />

                    <p>
                A avaliação consta sempre de um relatório detalhado que entregamos num curto espaço de tempo, para que auxilie a empresa cliente a:
                    </p>
                    <ul>
                        <li>
                    Fundamentar a decisão de admissão de novos colaboradores
                        </li>
                        <li>
                    Gerir Carreiras (decisões sobre promoções, mobilidade, desempenhos)
                        </li>
                        <li>
                    Identificar necessidades de formação
                        </li>
                    </ul>
                </div>

                <Element name="succession-plans" />
                <h3>
                    <span>4.</span>
                Plano de sucessão
                </h3>
                <p>
            Cada vez mais as empresas têm de antecipar cenários de mudança e traçar planos de acções para responder adequada e atempadamente aos desafios que se vão colocando.
Nesse sentido, identificamos pipeline de talento (interno e externo) para as funções chave da empresa, de forma a assegurar planos de sucessão que colmatem necessidades futuras, evitando o impacto negativo que uma mudança não planeada e meramente reactiva tem, muitas vezes, no negócio e nas equipas.
                </p>
                <p>
Uma das estratégias assenta na implementação de um programa de atracção de jovens talentos, nomeadamente recém licenciados, que possam ser formados para posições de gestão de topo e chefia intermédia ou para funções de elevada complexidade técnica.
A definição de planos de carreira para actuais colaboradores, com base na identificação das pessoas com maior potencial de desenvolvimento para assumir novas funções é outro eixo de actuação.

                </p>
                <div className={ classNames(Styles.subSection, Styles.last) }>
                    <h4>
               Metodologia
                    </h4>
            
                    <p>
                Identificação de potencial interno:
                    </p>
                    <ul>
                        <li>
                    Avaliação das aptidões, competências comportamentais e personalidade de actuais colaboradores, com recurso a diversos métodos e ferramentas.
                        </li>
                    </ul>

                    <p>
                Identificação de potencial interno:
                    </p>
                    <ul>
                        <li>
                    Identificação de potencial externo:
                        </li>
                        <li>
                    Desenvolvimento de um modelo diferenciado de recrutamento que permita identificar os melhores jovens talentos e proporcionar-lhes uma experiência de candidatura num formato inovador e atractivo;
                        </li>
                        <li>
                    Recurso a diferentes metodologias e ferramentas de avaliação que permitam selecionar os melhores candidatos. 
                        </li>
                    </ul>
                </div>
            </div>

            <CompanyForm />
        </div>
    );
};

export default ServicesCompaniesPage;