import { combineReducers } from 'redux';

import {
    SAVE_START,
    SAVE_SUCCESS,
    SAVE_FAIL,
    RESET,
} from './actions';

export const initialState = {
    saving: false,
};

export const saving = (state = initialState.saving, action) => {
    switch (action.type) {
    case SAVE_START:
        return true;
    case SAVE_SUCCESS:
    case SAVE_FAIL:
    case RESET:
        return false;
    default:
        return state;
    }
};

export const success = (state = initialState.saving, action) => {
    switch (action.type) {
    case SAVE_SUCCESS:
        return true;
    case SAVE_FAIL:
    case SAVE_START:
    case RESET:
        return false;
    default:
        return state;
    }
};

export default combineReducers({
    saving,
    success,
});
